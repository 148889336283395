<script lang="ts" setup>
import type {
  ComponentBlockTeaserHintFragment,
} from "~~/types/graphql";

import {
  Enum_Componentblockteaserhint_Variation,
} from "~~/types/graphql";

import Arrow from "@/assets/icons/arrow.svg?component";
const props = defineProps<{
  TeaserHintAnchor: ComponentBlockTeaserHintFragment["TeaserHintAnchor"];
  TeaserHintHeadline: ComponentBlockTeaserHintFragment["TeaserHintHeadline"];
  TeaserHintLink: ComponentBlockTeaserHintFragment["TeaserHintLink"];
  TeaserHintLinkExternal: ComponentBlockTeaserHintFragment["TeaserHintLinkExternal"];
  TeaserHintLinkTarget: ComponentBlockTeaserHintFragment["TeaserHintLinkTarget"];
  TeaserHintSubheadline: ComponentBlockTeaserHintFragment["TeaserHintSubheadline"];
  TeaserHintTag: ComponentBlockTeaserHintFragment["TeaserHintTag"];
  TeaserHintText: ComponentBlockTeaserHintFragment["TeaserHintText"];
  TeaserHintVariation: Enum_Componentblockteaserhint_Variation;
}>();

function link() {
  let linkUrl = false;

  if (props.TeaserHintLinkTarget === 'external') {
    linkUrl = props.TeaserHintLinkExternal || false;
  }
  // Default option 'page'
  else {
    linkUrl = props.TeaserHintLink.data?.attributes?.Slug || false;

    if (linkUrl && props.TeaserHintAnchor) {
      linkUrl = linkUrl + '#' + props.TeaserHintAnchor;
    }
  }

  return linkUrl;
}
</script>
<template>
  <layout-block
    :link="props.TeaserHintLink?.data?.attributes?.Slug"
    :class="`variation-${props.TeaserHintVariation?.toLowerCase()}`"
    class="teaser-hint"
  >
    <div
      class="flex flex-col justify-center items-center max-w-3xl mx-auto text-center"
    >
      <base-tag
        v-if="TeaserHintTag"
        class="teaser-tag mb-3 border-light-grey bg-light-grey"
      >
        {{ TeaserHintTag }}
      </base-tag>
      <h2
        class="text-h2 md:text-h1 2xl:text-h1max font-pixel max-w-prose-xs text-center grid place-content-center mb-3 hyphens-auto"
        v-if="TeaserHintHeadline"
      >
        {{ TeaserHintHeadline }}
      </h2>
      <base-text
        class="mb-8"
        :Text="TeaserHintText"
        v-if="TeaserHintText"
      >
      </base-text>
      <slot name="button">
        <base-button
          v-if="TeaserHintSubheadline && link()"
          :to="TeaserHintAnchor ? TeaserHintLink.data?.attributes?.Slug + '#' + TeaserHintAnchor : TeaserHintLink.data?.attributes?.Slug"
          transparent
          type="secondary"
          class="teaser-button border-integer-black hover:border-integer-black justify-center w-full xs:w-auto"
        >{{ TeaserHintSubheadline }}<template #icon-right><Arrow /></template
        ></base-button>
      </slot>
    </div>
  </layout-block>
</template>
<style scoped lang="scss">
.teaser-hint.variation-dark {
  @apply bg-integer-black text-integer-white;

  .teaser-button {
    @apply border-integer-white text-integer-white hover:bg-integer-white hover:border-integer-white hover:text-integer-black;
  }

  .teaser-tag {
    @apply bg-light-black border-light-black text-integer-white;
  }
}

.teaser-hint.variation-green {
  @apply bg-integer-light-green;

  .teaser-tag {
    @apply bg-integer-white border-integer-white;
  }
}

.teaser-hint.variation-grey {
  @apply bg-light-gray;

  .teaser-tag {
    @apply bg-integer-white border-integer-white;
  }
}
</style>
